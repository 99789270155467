<template>
  <div>
    <el-row>
<!--      <el-col :span="10">-->
<!--        <el-radio-group size="medium" class="mb-10" v-model="activeRadio" @input="handleRadio">-->
<!--          <el-radio-button v-for="(r, index) in radioList" :key="index"-->
<!--                           :label="r.name">{{ r.label }}</el-radio-button>-->
<!--        </el-radio-group>-->
<!--      </el-col>-->
      <el-col :span="12" class="mb-15">
        <formList
            formSize="small"
            :formLabel="searchFormLabel"
            :form="searchParams"
            formLabelWidth="0"
        ></formList>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="searchList" :loading="loading.listLoading">搜索</el-button>
      </el-col>
    </el-row>
    <router-view ref="tabRef" class="box-minH"></router-view>
    <div class="check-box">
      <el-checkbox v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>
      <el-button v-if="$route.name==='notInvoiceList'" class="fr" size="medium" type="warning"
                 @click="goTicket">去开票</el-button>
      <el-button v-if="$route.name==='invoiceList'&&$route.params.status==='1'" class="fr" size="medium" type="success"
                 @click="downloadFile">下载</el-button>
      <el-button v-if="$route.name==='invoiceList'&&$route.params.status==='4'" class="fr" size="medium" type="warning"
                 @click="reOpen">重开</el-button>
      <span class="fr fs-20 mr-50" style="line-height: 36px">
        合计：<span class="tc-error fw-b">￥{{ totalMoney }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import publicFun from "@/mixins/public";
import formList from "@/components/formList";

export default {
  name: "invoiceInfo",
  mixins: [publicFun],
  components: {
    formList
  },
  data() {
    return {
      radioList: [
        { label: '待开票', name: 'notInvoiceList' },
        { label: '开票成功', name: 'invoiceList' },
        { label: '开票失败', name: 'invoiceList_fail' },
      ],
      activeRadio: 'notInvoiceList',
      checkAll: false,
      searchFormLabel: [
        {
          colSpan: 10,
          label: '',
          type: 'text',
          key: 'mvLicense',
          placeholder: '车牌号'
        },
        {
          colSpan: 14,
          label: '',
          type: 'datetimerangePicker',
          typeName: 'datetimerange',
          key: 'datetime',
          startPlaceholder: '开始时间',
          endPlaceholder: '结束时间',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          width: '100%'
        },
      ],
      searchParams: {
        unionid: '',
        mvLicense: '',
        datetime: []
      },
      loading: {
        listLoading: false
      },
      totalMoney: 0
    }
  },
  watch: {
    '$route': function (val) {
      this.checkAll = false;
      this.totalMoney = 0;
    },
  },
  mounted() {
    this.activeRadio = this.$route.name;
    this.searchParams.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
  },
  methods: {
    handleRadio(tab, event) {
      Object.keys(this.searchParams).forEach(key => {
        if (key !== 'unionid') {
          this.searchParams[key] = '';
        }
      })
      if (tab === 'invoiceList') {
        this.$router.push({
          name: 'invoiceList',
          params: {
            status: '1'
          }
        });
      } else if (tab === 'invoiceList_fail') {
        this.$router.push({
          name: 'invoiceList',
          params: {
            status: '4'
          }
        });
      } else {
        this.goPage(tab);
      }
    },
    searchList() {
      this.$refs.tabRef.searchList(this.searchParams);
    },
    handleCheckAll() {
      this.$refs.tabRef.checkAll = this.checkAll;
      this.$refs.tabRef.handleCheckAll();
      this.$refs.tabRef.getTotal();
    },
    goTicket() {
      this.$refs.tabRef.goTicket();
    },
    downloadFile() {
      this.$refs.tabRef.downloadFile();
    },
    reOpen() {
      this.$refs.tabRef.reOpen();
    }
  }
}
</script>

<style scoped lang="scss">
.box-card:hover {
  cursor: pointer;
  img {
    animation-direction: alternate;
    transform: scale(1.1);
    transition: all 0.3s;
  }
}
.check-box {
  z-index: 10;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #d7d7d7;
  padding: 20px 10px 10px;
  width: 100%;
}
.el-checkbox {
  margin-right: 10px !important;
  ::v-deep {
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
      bottom: 3px;
      &::after {
        left: 6px;
        width: 5px;
        height: 12px;
      }
    }
    .el-checkbox__label {
      font-size: 20px;
      line-height: 36px;
    }
  }
}
.box-minH {
  height: calc(100vh - 80px - 48px - 10px - 40px - 56px - 48px - 67px);
  overflow-y: auto;
}
</style>